import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';

import FocusPanel from '../components/focus-panel';
import Actions from 'Redux/thunks/auth';
import CenteredLayout from '../layout/centered';

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);

    this.getStatus = () => {
      if (this.state.pleaseWait) {
        return <span className="notification-panel">Verifying token...</span>;
      }
      if (this.state.verified) {
        return (
          <span className="notification-panel">
            Token verified. Go back to <SesamLink to="/">dashboard</SesamLink>
          </span>
        );
      }
      return (
        <span className="error-panel">
          Token verification failed. The token might be invalid or expired.
        </span>
      );
    };

    this.state = {
      pleaseWait: true,
    };
  }

  componentDidMount() {
    this.props
      .verify(this.props.token)
      .then(() => {
        this.setState({
          pleaseWait: false,
          verified: true,
        });
      })
      .catch(() => {
        this.setState({
          pleaseWait: false,
          verified: false,
        });
      });
  }

  render() {
    return (
      <CenteredLayout>
        <FocusPanel>
          <h2 className="heading-page">Verify email</h2>
          <p>{this.getStatus()}</p>
        </FocusPanel>
      </CenteredLayout>
    );
  }
}

VerifyEmail.propTypes = {
  verify: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    token: ownProps.location.query['verify-emailaddress-token'],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verify: (token) => dispatch(Actions.verifyEmail(token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
