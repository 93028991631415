import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SesamModal from 'Common/SesamModal/SesamModal';
import JsonEditor from 'Common/JsonEditor/JsonEditor';
import Button from 'Common/Button/Button';
import SesamCheckboxFieldCompact from 'Common/SesamCheckboxFieldCompact/SesamCheckboxFieldCompact';
import ActionBar from '../action-bar';
import { connect } from 'react-redux';

function PostEntityModal(props) {
  const {
    sendPost,
    postMultiple,
    showPost,
    postValidationError,
    isPostDisabled,
    shouldForceIfDuplicate,
  } = props;
  const [preserveFloatTypes, setPreserveFloatTypes] = useState(true);
  function togglePreserveFloatTypes() {
    setPreserveFloatTypes((val) => !val);
  }
  const [force, setForce] = useState(shouldForceIfDuplicate);
  function toggleForce() {
    setForce((val) => !val);
  }

  const title = props.postEntitiesModal.title
    ? props.postEntitiesModal.title
    : postMultiple
    ? 'Post multiple entities'
    : 'Post entity';

  const subTitle = props.postEntitiesModal.subTitle
    ? props.postEntitiesModal.subTitle
    : postMultiple
    ? 'Multiple entities must be specified as an array'
    : 'Edit and post a single entity';

  return (
    <SesamModal
      className="postPopup"
      isOpen={showPost}
      onRequestClose={props.onClose}
      contentLabel={title}
      darkModeActive={props.darkModeActive}
    >
      <h3 className="heading-page">{title}</h3>
      <p>{subTitle}</p>
      <JsonEditor
        errors={postValidationError}
        onChange={props.onPostChange}
        onValidateJson={props.onPostValidation}
        rawJson={props.rawJson}
      />
      <ActionBar>
        <SesamCheckboxFieldCompact
          label="Preserve float types"
          CheckboxProps={{
            checked: preserveFloatTypes,
            onChange: () => togglePreserveFloatTypes(),
          }}
        />
        <SesamCheckboxFieldCompact
          label="Add if duplicate"
          CheckboxProps={{
            checked: force,
            onChange: () => toggleForce(),
          }}
        />
        <Button onClick={props.onClose} style={{ marginRight: '0.5rem' }}>
          Cancel
        </Button>
        <Button
          onClick={() => sendPost({ force, preserveFloatTypes })}
          theme="primary"
          disabled={isPostDisabled}
        >
          Post
        </Button>
      </ActionBar>
    </SesamModal>
  );
}

PostEntityModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPostChange: PropTypes.func.isRequired,
  onPostValidation: PropTypes.func.isRequired,
  rawJson: PropTypes.any,
  sendPost: PropTypes.func,
  postMultiple: PropTypes.bool,
  showPost: PropTypes.bool,
  postValidationError: PropTypes.string,
  isPostDisabled: PropTypes.bool,
  darkModeActive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  darkModeActive: state.theme.dark,
  postEntitiesModal: state.postEntitiesModal,
});

export default connect(mapStateToProps)(PostEntityModal);
