import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'Common/Button/Button';
import { getCurrentSub, currentUserIsAdmin } from 'Redux/thunks/subscriptions';
import { LoadingPanel } from 'Common/LoadingPanel';
import { getDatabrowserURL, getDatabrowserURLFromCustomFQDN } from '../settings-databrowser/index';

import './style.css';
import ExternalLink from 'Common/Links/ExternalLink';
import DangerText from 'Common/Text/DangerText';

class SettingsDatabrowserDatabrowser extends React.Component {
  constructor(props) {
    super(props);

    this.openInNewWindowButtonText = 'Open in new window';

    this.submitDatabrowserLoginForm = (
      databrowserURL,
      formTarget,
      databrowserUserIdToImpersonate,
      impersonateWithAdminPermissions
    ) => {
      let databrowserLoginUrl = `${databrowserURL}/jwt_login`;
      document.getElementById('databrowserloginform_jwt').value = this.props.token;
      document.getElementById('databrowserloginform_userIdToImpersonate').value =
        databrowserUserIdToImpersonate || '';
      document.getElementById('databrowserloginform_impersonateWithAdminPermissions').checked =
        impersonateWithAdminPermissions || false;
      const databrowserLoginForm = document.getElementById('databrowserloginform');
      databrowserLoginForm.action = databrowserLoginUrl;
      databrowserLoginForm.target = formTarget;
      databrowserLoginForm.submit();
      document.getElementById('databrowserloginform_jwt').value = '';
    };

    this.openDatabrowserInNewWindow = () => {
      let databrowserURL = getDatabrowserURLFromCustomFQDN(
        this.props.sub,
        this.props.params.databrowserId
      );
      if (!databrowserURL) {
        databrowserURL = getDatabrowserURL(this.props.sub, this.props.params.databrowserId);
      }
      this.submitDatabrowserLoginForm(databrowserURL, '_blank');
    };

    this.databrowserUserIdToImpersonateChanged = (event) => {
      this.setState({ databrowserUserIdToImpersonate: event.target.value });
    };

    this.openDatabrowserInNewWindowAsOtherUser = (impersonateWithAdminPermissions) => {
      let databrowserURL = getDatabrowserURLFromCustomFQDN(
        this.props.sub,
        this.props.params.databrowserId
      );
      if (!databrowserURL) {
        databrowserURL = getDatabrowserURL(this.props.sub, this.props.params.databrowserId);
      }
      // Note that we open the databrowser in the current tab, since that reduced the chance that the
      // management studio reload the databrowser page and messes up the http session.
      this.submitDatabrowserLoginForm(
        databrowserURL,
        '_self',
        this.state.databrowserUserIdToImpersonate,
        impersonateWithAdminPermissions
      );
    };

    this.state = { databrowserUserIdToImpersonate: '' };
  }

  componentDidMount() {
    const databrowseriframe = document.getElementById('databrowseriframe');
    const iframedoc = databrowseriframe.contentDocument || databrowseriframe.contentWindow.document;

    iframedoc.open();
    iframedoc.write(`<html>
  <head>
    <style type="text/css">
      #login-problem-message {
        color: #c93c37;
      }
    </style>
  </head>
  <body>
    <p>Logging in...</p>
    <div id="login-problem-message" style="display:none">
      <p>This seems to take longer than usual. Something has probably gone wrong.</p>
      <p>To resolve this problem you can try to reload this browser-page.</p>
      <p>If reloading the page doesn't work, you can try to open the Data Access Portal in a new window
         using the "${this.openInNewWindowButtonText}" button.
      </p>
    </div>
    <script type="text/javascript" language="javascript">
      setTimeout(function() {
        var loginProblemMessage = document.getElementById("login-problem-message");
        loginProblemMessage.style.display = "";
      }, 30000);
    </script>
  </body>
</html>`);
    iframedoc.close();
    const databrowserURL = getDatabrowserURL(this.props.sub, this.props.params.databrowserId);
    this.submitDatabrowserLoginForm(databrowserURL, 'databrowseriframe');
  }

  render() {
    const databrowserURL = getDatabrowserURL(this.props.sub, this.props.params.databrowserId);

    if (!databrowserURL) {
      return <LoadingPanel loadingMessage="Provisioning…" />;
    }

    return (
      <main className="scrollArea">
        <div className="row">
          <div className="col col--elastic gr-primary">
            <form id="databrowserloginform" style={{ display: 'none' }} method="POST">
              <input id="databrowserloginform_jwt" name="jwt" type="text" />
              <input
                id="databrowserloginform_userIdToImpersonate"
                name="user_id_to_impersonate"
                type="text"
              />
              <input
                id="databrowserloginform_impersonateWithAdminPermissions"
                name="impersonate_with_admin_permissions"
                type="checkbox"
              />

              <Button type="commit">Login</Button>
            </form>

            <iframe id="databrowseriframe" name="databrowseriframe" />
          </div>
          <div className="col gr-secondary">
            <p>
              This page displays the Data Access Portal GUI using your current login credentials. If
              you want to open the Data Access Portal in a new browser window, click the button
              beneath.
            </p>
            <Button onClick={this.openDatabrowserInNewWindow}>
              {this.openInNewWindowButtonText}
            </Button>

            {this.props.userIsAdmin && (
              <div className="databrowser-danger-area">
                <hr />
                <h2 className="heading-section">
                  <DangerText>Danger area</DangerText>
                </h2>
                <p>
                  Since you are an admin-user, you can also log in as a different user in the Data
                  Access Portal. This is useful to debug problems with the Data Access Portal. (Note
                  that such logins are logged to the Data Access Portal's logfile).
                </p>
                <p>
                  Enter the userid (for instance email/phonenumber) you want to log in as and click
                  the 'Impersonate' button below:
                </p>

                <input
                  type="text"
                  value={this.state.databrowserUserIdToImpersonate}
                  onChange={this.databrowserUserIdToImpersonateChanged}
                />

                <Button
                  disabled={!this.state.databrowserUserIdToImpersonate}
                  onClick={(event) => this.openDatabrowserInNewWindowAsOtherUser(false)}
                >
                  Impersonate
                </Button>

                <Button
                  disabled={!this.state.databrowserUserIdToImpersonate}
                  title="This impersonates the specified user, but also keeps your admin-permissions in the data access portal."
                  onClick={(event) => this.openDatabrowserInNewWindowAsOtherUser(true)}
                >
                  Impersonate (admin)
                </Button>

                <p>
                  <strong>Caveats:</strong> Note that the Data Access Portal user-information is
                  stored in an{' '}
                  <ExternalLink href="https://en.wikipedia.org/wiki/Hypertext_Transfer_Protocol#HTTP_session_state">
                    http session
                  </ExternalLink>
                  . That is; the information about the currently logged in user is stored in a
                  server side session, and the session-id is stored in a client-side cookie. That
                  means that if you log into the Data Access Portal in another browser tab, the
                  "impersonation"-session is lost, since the session-id cookie will be overwritten.
                  <em>
                    This includes opening the "Data Access Portal" page in the Sesam portal GUI.
                  </em>
                </p>
              </div>
            )}
          </div>
        </div>
      </main>
    );
  }
}

SettingsDatabrowserDatabrowser.propTypes = {
  isItestMode: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  sub: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

SettingsDatabrowserDatabrowser.contextTypes = {
  // https://github.com/reactjs/react-router/issues/975
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isItestMode: state.globals.status.is_itest_mode,
    sub: getCurrentSub(state),
    userIsAdmin: currentUserIsAdmin(state),
    subId: state.subscription.id,
    token: state.subscription.token,
  };
}

export default connect(mapStateToProps)(SettingsDatabrowserDatabrowser);
