import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Ellipsis from 'Common/Ellipsis';
import Copy from '../../../images/icons/copy_white.svg';
import useClipboard from '../../../hooks/useClipboard';
import { DatasetInspectorContext } from '../DatasetInspectorContext';

function DatasetInspectorViewerEntityHeader(props) {
  const { addToast } = props;
  const [state] = useContext(DatasetInspectorContext);

  const selectedEntityId = state.selectedEntity._id;

  useClipboard('.dataset-inspector-viewer__entity-header-copy-button', () => {
    addToast('Copied to clipboard!');
  });
  return (
    <React.Fragment>
      <Ellipsis>{selectedEntityId}</Ellipsis>
      <button
        className="btn btn--small dataset-inspector-viewer__entity-header-copy-button"
        title="Copy ID to clipboard"
        data-clipboard-text={selectedEntityId}
      >
        <Copy style={{ width: '18px', height: '18px' }} />
      </button>
    </React.Fragment>
  );
}

DatasetInspectorViewerEntityHeader.propTypes = {
  addToast: PropTypes.func.isRequired,
};

export default DatasetInspectorViewerEntityHeader;
