import { createAction, createReducer } from '@reduxjs/toolkit';

import { persistURLService } from 'Internals/connectionString.utils';
import { AppThunk } from 'src';

export type PostEntitiesModalArgs = {
  title: string;
  subTitle: string;
};

export const setModalInfoAction = createAction('info/set', (info: PostEntitiesModalArgs) => {
  return {
    payload: info,
  };
});

export const PostEntitiesModalThunk = {
  setModalInfo(title: string, subTitle: string): AppThunk {
    return (dispatch) => {
      dispatch(setModalInfoAction({ title, subTitle }));
    };
  },
};

const initialState: PostEntitiesModalArgs = { title: '', subTitle: '' };

export const postEntitiesModal = createReducer(initialState, (builder) =>
  builder.addCase(setModalInfoAction, (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  })
);
