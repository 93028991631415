import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import InputWithButton from 'Common/InputWithButton/InputWithButton';
import { LoadingPanel } from 'Common/LoadingPanel';

import MonitoringActions from 'Redux/thunks/monitoring';
import SubscriptionsActions, {
  getCurrentSub,
  valuesFromOperations,
} from 'Redux/thunks/subscriptions';
import { datahubInfoSelector } from 'Redux/selectors';
import { getDatahubIsOutdated } from 'Internals/datahub';

import { toastAdded } from 'Redux/thunks/global';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import ExternalLink from 'Common/Links/ExternalLink';
import HighlightText from 'Common/Text/HighlightText';
import DangerText from 'Common/Text/DangerText';
import DatahubActions from 'Redux/thunks/datahub';
import SesamModal from 'Common/SesamModal/SesamModal';
import UpdateBasicsForm from '../../components/update-basics-form';
import { propTypesFromOperations } from '../../../model-props';
import ConfirmDelete from '../../components/confirm-delete';
import DataTable from '../../../components/data-table';
import Feedback from '../../../components/feedback';
import KeyValue from '../../../components/key-value';
import PromiseButton from '../../../components/promise-button';
import DiskUsageGraph from './DiskUsageGraph';

import './style.css';
import { getEnvironment } from 'Internals/utils';

const styles = (theme) => ({
  danger: {
    color: theme.palette.secondary.main,
  },
});

const operations = ['delete_subscription', 'leave_subscription', 'modify_subscription'];

// TODO: replace this with some lib?
function getHumanizedDiskUsage(value) {
  const valueAsNumber = Number(value);
  const gigaBytes = (valueAsNumber / 1e9).toFixed(1);
  return `${gigaBytes} GB`;
}

// TODO: This needs to be broken into individual components

function getProvisionedBy(service, version) {
  if (service === 'on-premise') return 'Self-hosted';
  // service === 'in-cloud' or other forms
  else {
    if (!isNaN(parseInt(version))) return `version ${version}`;
    return 'custom';
  }
}

class SettingsBasicsPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnUpdateDetails = (name, description, url) => {
      return this.props.updateBasics(this.props.sub.id, name, description, url);
    };

    this.confirmOnLeave = () => {
      this.setState({
        leaving: false,
        showLeaveConfirm: true,
      });
    };

    this.cancelOnLeave = () => {
      this.setState({ showLeaveConfirm: false });
    };

    this.approveOnLeave = () => {
      this.setState({ leaving: true });
      return this.props.onLeave(this.props.sub.id).catch(() => this.setState({ leaving: false }));
    };

    this.confirmOnDelete = () => {
      this.setState({
        showDeleteConfirm: true,
      });
    };

    this.cancelOnDelete = () => {
      this.setState({ showDeleteConfirm: false });
    };

    this.approveOnDelete = () => {
      return this.props.onDelete(this.props.sub.id);
    };

    this.updateSelectedTag = (e) => {
      const value = e.target.value;
      this.setState({
        selectedTag: value,
      });
    };

    this.handleUpdateTag = () => {
      if (!this.state.selectedTag) return;
      this.setState({
        updatingTag: true,
      });

      this.props.updateTag(this.props.sub.id, this.state.selectedTag).then(() => {
        this.setState({
          updatingTag: false,
        });
        this.props.addToast('The target software channel has been updated');
      });
    };

    this.refresh = () => {
      this.props.refreshSubscription(this.props.sub.id);
      this.setState((state) => ({ ...state, sub: props.sub }));

      if (this.props.connected) this.props.refreshDatahubInfo();

      this.props
        .getData(this.props.sub.id)
        .then((data) => this.setState({ data }))
        .catch(() => this.setState({ data: null }));
      this.props
        .getInvoiceData(this.props.sub.id)
        .then((invoiceData) => this.setState({ invoiceData }))
        .catch(() => this.setState({ invoiceData: null }));
    };

    this.state = {
      showLeaveConfirm: false,
      showDeleteConfirm: false,
      data: null,
      invoiceData: null,
      selectedTag: 'none',
      updatingTag: false,
    };
  }

  componentDidMount() {
    this.refresh();
    this.props.registerRefresh(this.refresh);
  }

  componentWillUnmount() {
    this.props.unregisterRefresh(this.refresh);
  }

  render() {
    if (!this.props.datahubInfo) {
      return <div />;
    }

    const buildDate =
      this.props.connected &&
      this.props.datahubInfo.status &&
      this.props.datahubInfo.status['build-date'];
    const buildDateString =
      this.props.connected &&
      `${moment(buildDate).format('MMMM Do YYYY, h:mm:ss a')}
       (${moment.duration(moment().diff(buildDate)).humanize()} ago)`;

    const startTime =
      this.props.connected &&
      this.props.datahubInfo.status &&
      this.props.datahubInfo.status['start_time'];
    const startTimeString =
      this.props.connected &&
      `${moment(startTime).format('MMMM Do YYYY, h:mm:ss a')}
       (${moment.duration(moment().diff(startTime)).humanize()} ago)`;

    const datahubSize = get(this.props.sub, 'products.datahub.size');
    let diskUsageGraph;

    if (!this.props.connected) {
      diskUsageGraph = <em>Not connected</em>;
    } else if (!this.state.data) {
      diskUsageGraph = <LoadingPanel loadingMessage={'Loading stats…'} size="medium" />;
    } else {
      diskUsageGraph = (
        <DiskUsageGraph
          connected={this.props.connected}
          data={this.state.data}
          datahubSize={datahubSize}
        />
      );
    }

    let invoiceInfoTable;
    if (!this.state.invoiceData) {
      invoiceInfoTable = (
        <LoadingPanel loadingMessage={'Loading invoicing information…'} size="medium" />
      );
    } else if (this.state.invoiceData.length > 0) {
      invoiceInfoTable = (
        <DataTable
          id="subscription-invoicing"
          data={this.state.invoiceData}
          dataKey="date"
          cols={[
            { header: 'Date', type: 'date', data: 'date' },
            {
              header: 'Disk usage',
              type: 'number',
              data: (row) => getHumanizedDiskUsage(row.disk_usage),
            },
          ]}
        />
      );
    } else {
      invoiceInfoTable = <em>No invoicing data available</em>;
    }

    if (!this.props.sub) {
      return null; // Sub could have been removed if user deletes/leaves it
    }

    const tags = [
      { value: 'weekly-prod', display: 'weekly-prod', val: 1000 },
      { value: 'weekly', display: 'weekly', val: 100 },
      { value: 'nightly', display: 'nightly', val: 10 },
      { value: 'latest', display: 'latest (unstable)', val: 1 },
      { value: this.props.tag, display: this.props.tag, val: -2000 },
      { value: this.props.targetTag, display: this.props.targetTag, val: 2000 },
    ];

    let currentTag = tags.find((x) => x.value === this.props.tag);
    let targetTag = tags.find((x) => x.value === this.props.targetTag);

    const environment = getEnvironment(get(this.props.sub, 'products'));
    if (!get(targetTag, 'display')) {
      switch (environment) {
        case 'developer':
        case 'developer-pro':
          targetTag = { value: 'nightly', display: 'nightly', val: 10 };
          break;
        case 'production':
          targetTag = { value: 'weekly-prod', display: 'weekly-prod', val: 1000 };
          break;
      }
    }

    let currentTagHtml = (
      <p>
        Current channel: <code>{`${get(currentTag, 'display') || 'not available'}`}</code>
      </p>
    );
    let targetTagHtml = (
      <p>
        Target channel: <code>{`${get(targetTag, 'display') || 'not set'}`}</code>
      </p>
    );
    let msg;
    let targetTagVal = get(targetTag, 'val', 0);
    let currentTagVal = get(currentTag, 'val', 0);

    if (currentTagVal - targetTagVal < 0) {
      msg = (
        <p className={this.props.classes.danger}>
          This change may, in some cases, take several days. Since newer versions might modify the
          database storage format in ways that are not backwards compatible, it is generally not
          safe to switch to an older version of the datahub. The system will therefore not switch to
          using the selected software channel until the version with the selected software channel
          is more recent than version that the datahub is currently running on.
        </p>
      );
    } else if (currentTagVal - targetTagVal > 0) {
      msg = (
        <p className={this.props.classes.danger}>
          Changing the software channel usually takes some time. Once changed, it will be reflected
          in the &quot;current channel&quot;.
        </p>
      );
    } else {
      msg = null;
    }

    let tagInput;
    tagInput = this.props.isUserPortalAdmin ? (
      <div>
        <div style={{ marginBottom: '20px' }} className={this.props.classes.danger}>
          Caution. This needs to be a valid software channel from Dockerhub.
        </div>
        <InputWithButton
          textFieldProps={{
            value: this.state.selectedTag,
            onChange: this.updateSelectedTag,
            type: 'text',
          }}
          buttonProps={{
            text: 'Set software channel',
            onClick: this.handleUpdateTag,
            state: 'danger',
            disabled: this.state.updatingTag,
            loading: this.state.updatingTag,
          }}
        />
      </div>
    ) : (
      <Form onSubmit={this.handleUpdateTag}>
        <SesamTextField
          label="Select from available channels"
          margin="normal"
          select
          id="selecttag"
          value={this.state.selectedTag}
          onChange={this.updateSelectedTag}
        >
          {tags.map((tag) => {
            return (
              <SesamMenuItem key={tag.value} value={tag.value}>
                {tag.display}
              </SesamMenuItem>
            );
          })}
        </SesamTextField>
        <Button
          type="submit"
          disabled={this.state.selectedTag === 'none' || this.state.updatingTag}
          theme="danger"
          loading={this.state.updatingTag}
        >
          Set software channel
        </Button>
      </Form>
    );

    const keyValues = {
      'Subscription ID': this.props.sub.id,
      'Subscription type': this.props.sub.type,
      'Provisioned by': getProvisionedBy(this.props.service, this.props.provisionerVersion),
      'Provisioning status': this.props.provisioningStatus,
      Version:
        this.props.connected && this.props.datahubInfo.status ? (
          this.props.datahubInfo.status.version
        ) : (
          <em>Not connected</em>
        ),
      'Code revision':
        this.props.connected && this.props.datahubInfo.status ? (
          <ExternalLink href="https://docs.sesam.io/changelog.html">
            {this.props.datahubInfo.status.revision}
          </ExternalLink>
        ) : (
          <em>Not connected</em>
        ),
      'Build date': this.props.connected ? buildDateString : <em>Not connected</em>,
      'Start time': this.props.connected ? startTimeString : <em>Not connected</em>,
      Statistics: diskUsageGraph,
      'Invoicing information': invoiceInfoTable,
    };

    // IS-5208: The 'start_time' attribute in the status from the sesam-node was recently added, so old versions
    //          doesn't report it.
    if (!startTime) {
      delete keyValues['Start time'];
    }

    return (
      <main className="scrollArea">
        <h2 className="heading-section">Subscription basics</h2>
        <div className="row">
          <div className="col gr-equal">
            <UpdateBasicsForm
              name={this.props.sub.name}
              description={this.props.sub.description}
              url={this.props.sub.url}
              onUpdate={this.handleOnUpdateDetails}
              canChange={this.props.can_modify_subscription.enabled}
            />
            <h2 className="heading-section">
              <DangerText>Danger area</DangerText>
            </h2>
            <Form component="div" standout>
              <h3 className="heading-component">
                <DangerText>Leave subscription</DangerText>
              </h3>
              <p>
                This will remove the subscription from your dashboard, and you will need to be
                invited to regain access. It will not affect other users or the datahub.
              </p>
              {!this.props.can_leave_subscription.enabled && (
                <HighlightText>{this.props.can_leave_subscription['reason-msg']}</HighlightText>
              )}
              <FormActions>
                <Button
                  text="Leave…"
                  theme="danger"
                  disabled={!this.props.can_leave_subscription.enabled}
                  onClick={this.confirmOnLeave}
                />
              </FormActions>
            </Form>
            <Form component="div" standout>
              <h3 className="heading-component">
                <DangerText>Delete subscription</DangerText>
              </h3>
              <p>
                This will delete the subscription, including the datahub and all its data. There is
                no way to restore data after this operation.
              </p>
              {!this.props.can_delete_subscription.enabled && (
                <HighlightText>{this.props.can_delete_subscription['reason-msg']}</HighlightText>
              )}
              <FormActions>
                <Button
                  onClick={this.confirmOnDelete}
                  theme="danger"
                  disabled={!this.props.can_delete_subscription.enabled}
                >
                  Delete…
                </Button>
              </FormActions>
              <SesamModal
                className="simple-dialog"
                isOpen={this.state.showLeaveConfirm}
                onRequestClose={this.cancelOnLeave}
                contentLabel="Confirmation"
              >
                <h2 className="heading-page">Please confirm</h2>
                <p>
                  Do you want to leave the subscription &quot;
                  {this.props.sub.name}
                  &quot;?
                </p>
                <div className="toolbar toolbar--right">
                  <Button onClick={this.cancelOnLeave} disabled={this.state.leaving}>
                    Cancel
                  </Button>
                  <PromiseButton onClick={this.approveOnLeave} pending="Leaving…" theme="danger">
                    Yes, leave
                  </PromiseButton>
                </div>
              </SesamModal>
              {this.state.showDeleteConfirm && (
                <ConfirmDelete
                  onCancel={this.cancelOnDelete}
                  onApprove={this.approveOnDelete}
                  subscription={this.props.sub}
                />
              )}
            </Form>
            {this.props.service === 'in-cloud' && (
              <Form component="div" standout>
                <h3 className="heading-component">
                  <DangerText>Change software channel</DangerText>
                </h3>
                {currentTagHtml}
                {targetTagHtml}
                {msg}
                {tagInput}
              </Form>
            )}
          </div>
          <div className="col gr-equal">
            {this.props.datahubOutdated && (
              <Feedback type="error">
                The service instance is running a version that is too old. This voids the SLA.
                Please upgrade or contact support.
              </Feedback>
            )}
            <KeyValue list={keyValues} />
          </div>
        </div>
      </main>
    );
  }
}

SettingsBasicsPage.propTypes = Object.assign(propTypesFromOperations(operations), {
  connected: PropTypes.bool.isRequired,
  datahubInfo: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  sub: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    licenses: PropTypes.array.isRequired,
    connections: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    products: PropTypes.shape({
      datahub: PropTypes.string,
    }),
  }),
  updateBasics: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getData: PropTypes.func.isRequired,
  getInvoiceData: PropTypes.func.isRequired,
  refreshDatahubInfo: PropTypes.func.isRequired,
  tag: PropTypes.string,
  targetTag: PropTypes.string,
  updateTag: PropTypes.func,
  service: PropTypes.string,
  datahubOutdated: PropTypes.bool,
  registerRefresh: PropTypes.func.isRequired,
  unregisterRefresh: PropTypes.func.isRequired,
  addToast: PropTypes.func,
  refreshSubscription: PropTypes.func,
  isUserPortalAdmin: PropTypes.bool,
  provisionerVersion: PropTypes.strinng,
  provisioningStatus: PropTypes.string,
});

SettingsBasicsPage.defaultProps = {
  datahubInfo: {},
};

function mapStateToProps(state) {
  const sub = getCurrentSub(state);
  const datahubInfo = datahubInfoSelector(state);
  const datahubOutdated = getDatahubIsOutdated(datahubInfo);

  return Object.assign(valuesFromOperations(sub, operations), {
    service: get(sub, 'service', ''),
    provisionerVersion: get(sub, 'provisioner_version', ''),
    provisioningStatus: get(sub, 'provisioning_status', ''),
    tag: get(sub, 'provisioning_result.datahub.tag'),
    targetTag: get(sub, 'products.datahub.version'),
    isUserPortalAdmin: state.user.isPortalAdmin,
    connected: state.subscription.connected,
    datahubInfo: state.subscription.info,
    sub,
    datahubOutdated: datahubOutdated,
  });
}

function mapDispatchToProps(dispatch) {
  return {
    onDelete: (subId) => dispatch(SubscriptionsActions.delete(subId)),
    onLeave: (subId) => dispatch(SubscriptionsActions.leave(subId)),
    updateBasics: (id, name, description, url) =>
      dispatch(SubscriptionsActions.updateBasics(id, name, description, url)),
    getData: (subId) => dispatch(MonitoringActions.getSubscriptionStats(subId, false)),
    getInvoiceData: (subId) => dispatch(MonitoringActions.getInvoiceData(subId, false)),
    refreshDatahubInfo: () => dispatch(DatahubActions.getInfo()),
    updateTag: (id, tag) => dispatch(SubscriptionsActions.updateTag(id, tag)),
    addToast: (msg) => dispatch(toastAdded({ message: msg, type: 'success' })),
    refreshSubscription: (subId) => dispatch(SubscriptionsActions.refresh(subId)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingsBasicsPage))
);
