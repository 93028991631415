import { makeStyles } from '@material-ui/core/styles';
import { push } from 'react-router-redux';
import { State, Theme } from '../types/types';
import { useSelector, useDispatch } from 'react-redux';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import Bg from '../images/forside3.png';

import { Formik, Form, FormikValues, FormikHelpers } from 'formik';
import AuthActions from 'Redux/thunks/auth';
import Button from '@material-ui/core/Button';
import CenteredLayout from '../layout/centered';
import Check from '@material-ui/icons/Check';

import Paper from '@material-ui/core/Paper';
import PasswordMeterTextField from 'Common/PasswordMeterTextField/PasswordMeterTextField';
import Recaptcha from 'react-google-recaptcha';
import SesamCheckboxField from 'Common/SesamCheckboxField/SesamCheckboxField';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import ExternalLink from 'Common/Links/ExternalLink';
import useOnOutsideClick from '../hooks/useOnOutsideClick';
import { SignupData } from '../api/auth';

const minPasswordLength = 12;

const useStyle = makeStyles((theme: Theme) => {
  return {
    paper: {
      maxWidth: '800px',
      display: 'flex',
    },
    pitch: {
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      padding: '46px 30px',
      alignItems: 'baseline',
      backgroundImage: `url(${Bg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    logo: { width: '150px', color: theme.palette.primary.main },
    point: {
      display: 'flex',
      alignItems: 'end',
      marginBottom: '35px',
      color: theme.palette.primary.main,
      '& span': {
        marginLeft: '10px',
        marginTop: '2px',
        color: 'white',
      },
    },
    signup: {
      padding: '40px',
    },
    headline: {
      fontSize: '26px',
      fontWeight: '300',
      marginBottom: '18px',
    },
    form: {
      maxWidth: '400px',
    },
    termsText: {
      fontSize: '14px',
      fontWeight: '400',
    },
    actions: {
      marginTop: '23px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  email: Yup.string().email('Must be a valid email').required('This field is required'),
  password: Yup.string()
    .min(
      minPasswordLength,
      `Password is too short - should be min ${minPasswordLength} characters long`
    )
    .required('This field is required'),
  passwordConfirm: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  termsAccepted: Yup.boolean().required('You must accept').oneOf([true]),
});

type SignupProps = {
  location: Location;
};

const initialValues = {
  email: '',
  name: '',
  password: '',
  passwordConfirm: '',
  termsAccepted: false,
};

const Signup: React.FC<SignupProps> = ({ location }) => {
  const classes = useStyle();

  const isItestMode = useSelector((state: State) => state.globals.status.is_itest_mode);

  const dispatch = useDispatch();

  const attemptSignup = (data: SignupData) => dispatch(AuthActions.attemptSignup(data));
  const redirect = (location: Location) => dispatch(push(location));
  const welcome = () => dispatch(push('/'));

  const recaptchaRef = useRef({});

  // this is a massive hack to be able to detect
  // when the user clicked outside the recaptcha window
  const labelRef = useRef(null);
  const [captchaRunning, setCaptchaRunning] = useState(false);
  const resetFormCallbackRef = useRef(() => {});
  function onRecaptchaOutsideClick() {
    if (captchaRunning && !isItestMode) {
      recaptchaRef.current.reset();
      resetFormCallbackRef.current(initialValues);
      setCaptchaRunning(false);
    }
  }
  useOnOutsideClick(labelRef, onRecaptchaOutsideClick);

  const signup = (data: SignupData) => {
    const redirectFromQuery = location.query.redirect;
    return attemptSignup(data).then(() => {
      if (redirectFromQuery && !redirectFromQuery.startsWith('/auth/signup')) {
        redirect({
          pathname: redirectFromQuery,
          query: (location.state && location.state.query) || null,
        });
      } else {
        welcome();
      }
    });
  };

  async function handleSubmit(values: FormikValues, actions: FormikHelpers<any>) {
    function softResetForm(e: {
      responseBody: {
        status: number;
        detail: string;
      };
    }) {
      actions.setFieldValue('password', '', false);
      actions.setFieldValue('passwordConfirm', '', false);
      actions.setFieldValue('termsAccepted', false, false);
      if (e.responseBody.status === 409) {
        console.log('409 detected');
        actions.setFieldError('email', e.responseBody.detail);
      }
      actions.setSubmitting(false);
    }

    if (isItestMode) {
      signup(values).catch(softResetForm);
    } else {
      let token = '';
      setCaptchaRunning(true);
      resetFormCallbackRef.current = actions.resetForm;
      try {
        token = await recaptchaRef.current.executeAsync();
      } catch (e) {
        // recaptcha failed
        actions.resetForm();
        recaptchaRef.current.reset();
      } finally {
        setCaptchaRunning(false);
      }
      values['g-recaptcha-response'] = token;
      try {
        await signup(values);
      } catch (e) {
        softResetForm(e);
      }
    }
  }

  return (
    <CenteredLayout>
      <Paper className={classes.paper}>
        <div className={`${classes.pitch}`}>
          <div className={classes.point}>
            <Check />
            <span>Data Tranformation Language for transforming data</span>
          </div>
          <div className={classes.point}>
            <Check /> <span>Management Studio for configuration and monitoring</span>
          </div>
          <div className={classes.point}>
            <Check /> <span>Adaptors for existing systems</span>
          </div>
          <div className={classes.point}>
            <Check /> <span>Powerful extension model for custom data sources and sinks</span>
          </div>
          <div className={classes.point}>
            <Check /> <span>Dashboard showing data flow health, and data volumes</span>
          </div>
          <div className={classes.point}>
            <Check /> <span>Configure notifications of unusual data flow patterns</span>
          </div>
        </div>
        <div className={classes.signup}>
          <div className={classes.headline}>Empower your business</div>
          <div className={classes.form}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={SignupSchema}
            >
              {({ values, errors, touched, isValid, isSubmitting, handleChange, handleBlur }) => {
                return (
                  <Form>
                    <SesamTextField
                      label="Name"
                      name="name"
                      size="small"
                      margin="normal"
                      InputProps={{
                        autoFocus: true,
                        id: 'name',
                      }}
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={(touched.name && errors.name) || ' '}
                    />
                    <SesamTextField
                      label="Email"
                      name="email"
                      size="small"
                      margin="normal"
                      InputProps={{
                        id: 'email',
                      }}
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={(touched.email && errors.email) || ' '}
                    />
                    <PasswordMeterTextField
                      label="Password"
                      type="password"
                      name="password"
                      size="small"
                      margin="normal"
                      InputProps={{
                        id: 'password',
                      }}
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                    <SesamTextField
                      label="Confirm password"
                      type="password"
                      name="passwordConfirm"
                      size="small"
                      margin="normal"
                      InputProps={{
                        id: 'passwordConfirm',
                      }}
                      value={values.passwordConfirm}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
                      helperText={(touched.passwordConfirm && errors.passwordConfirm) || ' '}
                    />
                    <SesamCheckboxField
                      FormControlProps={{
                        className: classes.terms,
                      }}
                      label={
                        <span className={classes.termsText} ref={labelRef}>
                          I agree to the{' '}
                          <ExternalLink
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://sesam.io/terms.html"
                          >
                            Terms of Service
                          </ExternalLink>{' '}
                          and{' '}
                          <ExternalLink
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://sesam.io/privacy.html"
                          >
                            Privacy Policy
                          </ExternalLink>
                        </span>
                      }
                      CheckboxProps={{
                        name: 'termsAccepted',
                        'data-selenium': 'agreeTerms',
                        checked: values.termsAccepted,
                        onBlur: handleBlur,
                        onChange: handleChange,
                        tabIndex: 4,
                      }}
                    />
                    <div className={classes.actions}>
                      {!isItestMode && (
                        <div className={classes.recaptcha}>
                          <Recaptcha
                            ref={recaptchaRef}
                            sitekey="6Lcsr8IZAAAAAJQoK5c_BTn0VTf10d4ur6LLuD7g"
                            size="invisible"
                            badge="inline"
                            hl="en"
                          />
                        </div>
                      )}
                      <div className={classes.signupButton}>
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          disabled={!isValid || isSubmitting}
                        >
                          {isSubmitting ? 'Signing up...' : 'Sign up'}
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Paper>
    </CenteredLayout>
  );
};

export default Signup;
