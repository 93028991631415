import React, { useCallback, useMemo } from 'react';
import { apiConfSelector } from 'Redux/selectors';
import { useSelector } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';

import { getPipeQueueSize } from 'Internals/pipes';
import Numeric from 'Common/renderers/Numeric/Numeric';
import Bytes from 'Common/renderers/Bytes/Bytes';
import EntitiesAPI, { EntitiesApiConf } from '../../api/entities';
import useResourceInject from '../../hooks/useResourceInject';
import { capitalize } from '../../utils/PipeUtils';
import { Dataset, Pipe, PipeRuntime } from '../../types/types';
import { PipeProgressStatus } from './PipeProgressStatus';
import { PipeRunningStatus } from './PipeRunningStatus';
import { PipePopulationStatus } from './PipePopulationStatus';
import { PipeLeafStatus } from './PipeLeafStatus';
import { PipeDisabledStatus } from './PipeDisabledStatus';
import { PipeSyncStatus } from './PipeSyncStatus';
import { PipeCompletenessStatus } from './PipeCompletenessStatus';
import { PipeAverageProcessTimeStatus } from './PipeAverageProcessTimeStatus';
import { PipeNextRunStatus } from './PipeNextRunStatus';
import { PipeLastRunStatusContainer } from './PipeLastRunStatus.container';
import './PipeStatusStyle.css';

type RenderObject = {
  key: string;
  val: any;
  render: Function;
};

export const StatusClassName = {
  GREEN: 'status-green',
  RED: 'status-red',
};

const PipeStatus: React.FC<{
  pipe: Pipe;
  sinkDataset: Dataset;
  subId: string;
  isPipeLeaf: boolean;
  theme: any;
}> = ({ pipe, sinkDataset, subId, isPipeLeaf, theme }) => {
  // Get dataset metrics
  let dataset = sinkDataset;
  let completeLog;
  let indexWithoutDeleted;
  let index;

  const apiConf = useSelector(apiConfSelector);

  const _getLastPumpDisabledEntity = useCallback(() => {
    if (!pipe._id) return Promise.resolve(null);

    const entityApiConf = apiConf as EntitiesApiConf;
    entityApiConf.dataset = `system:pump:${pipe._id}`;
    return EntitiesAPI.getEntity(entityApiConf, 'pump-disabled');
  }, [pipe._id, apiConf]);

  const [lastPumpDisabledEntity] = useResourceInject(_getLastPumpDisabledEntity, true);

  let disableComment = useMemo(() => {
    if (lastPumpDisabledEntity && lastPumpDisabledEntity.comment) {
      return lastPumpDisabledEntity.comment;
    } else {
      return null;
    }
  }, [lastPumpDisabledEntity]);

  const sinkType = get(pipe, 'config.effective.sink.type');
  if (sinkType === 'dataset' && dataset) {
    completeLog = get(dataset, 'runtime.count-log-exists');
    const countIndexExists = get(dataset, 'runtime.count-index-exists');
    const countIndexDeleted = get(dataset, 'runtime.count-index-deleted');
    indexWithoutDeleted =
      countIndexExists && countIndexDeleted ? countIndexExists - countIndexDeleted : null;

    index = get(dataset, 'runtime.count-index-exists');
  }

  // Get storage metrics
  let bytesUsed;
  let storage = get(pipe, 'storage');
  bytesUsed = isObject(storage) ? get(storage, 'total') : storage;

  // Get progress metrics
  let processed = get(pipe, 'runtime.progress.processed');
  let total = get(pipe, 'runtime.progress.total-estimate');

  // Get queue
  const queueSize = getPipeQueueSize(pipe);

  // Push all metrics to array
  const metrics: RenderObject[] = [
    {
      key: 'Enabled?',
      val: get(pipe, 'runtime["is-disabled"]') ? true : null,
      render: (isDisabled: boolean) => <PipeDisabledStatus isDisabled={isDisabled} />,
    },
    {
      key: 'Comment',
      val: get(pipe, 'runtime["is-disabled"]') && disableComment ? disableComment : null,
      render: (val: boolean) => val,
    },
    {
      key: 'Total entities',
      val: completeLog,
      render: (completeLog: number) => <Numeric>{completeLog}</Numeric>,
    },
    {
      key: 'Index w/o deleted',
      val: indexWithoutDeleted,
      render: (indexWithoutDeleted: number) => <Numeric>{indexWithoutDeleted}</Numeric>,
    },
    {
      key: 'Index',
      val: index,
      render: (index: number) => <Numeric>{index}</Numeric>,
    },
    {
      key: 'Average process time',
      val: get(pipe, 'runtime.average-process-time'),
      render: (processTime: number) => <PipeAverageProcessTimeStatus processTime={processTime} />,
    },
    {
      key: 'Last seen',
      val: get(pipe, 'runtime.last-seen'),
      render: (val: string) => val,
    },
    {
      key: 'Bytes used',
      val: bytesUsed,
      render: (val: string) => <Bytes>{val}</Bytes>,
    },
    {
      key: 'Populated?',
      val: get(dataset, 'runtime.populated'),
      render: (val: boolean) => <PipePopulationStatus isPopulated={val} />,
    },
    {
      key: 'Leaf?',
      val: isPipeLeaf,
      render: (val: boolean) => <PipeLeafStatus isLeaf={val} />,
    },
    {
      key: 'Progress',
      val: processed && total ? { processed, total } : null,
      render: ({ processed, total }: { processed: number; total: number }) => (
        <PipeProgressStatus processed={processed} total={total} />
      ),
    },
    {
      key: 'In queue',
      val: queueSize > 0 ? queueSize : null,
      render: (queueSize: number) => <Numeric>{queueSize}</Numeric>,
    },
    {
      key: 'Running?',
      val: get(pipe, 'runtime["is-running"]'),
      render: (isRunning: boolean) => <PipeRunningStatus isRunning={isRunning} />,
    },
    {
      key: 'Last run',
      val: get(pipe, 'runtime'),
      render: (runtime: PipeRuntime) => (
        <PipeLastRunStatusContainer
          useLink={false}
          runtime={runtime}
          type={get(pipe, 'config.effective.type')}
          id={get(pipe, '_id')}
          subId={subId}
        />
      ),
    },
    {
      key: 'Next run',
      val: get(pipe, 'runtime[next-run]'),
      render: (nextRun: string) => (
        <PipeNextRunStatus nextRun={nextRun} type={get(pipe, 'config.effective.type')} />
      ),
    },
    {
      key: 'Continuation strategy',
      val: capitalize(get(pipe, 'runtime.continuation')),
      render: (val: string) => <span title={val}>{val}</span>,
    },
    {
      key: 'In sync',
      val: get(pipe, 'runtime["out_of_sync"]'),
      render: (outOfSync: string[]) => <PipeSyncStatus outOfSync={outOfSync} />,
    },
    {
      key: 'Completeness',
      val: get(sinkDataset, 'runtime.completeness'),
      render: (completeness: number) => (
        <PipeCompletenessStatus completeness={completeness} pipeId={pipe._id} subId={subId} />
      ),
    },
  ];

  const filteredMetrics = metrics.filter((c) => !isUndefined(c.val) && !isNull(c.val));

  if (isEmpty(filteredMetrics)) {
    return (
      <div className="pipe-status">
        <div className="pipe-status__msg">No stats to show...</div>
      </div>
    );
  }

  return (
    <div className="pipe-status">
      {filteredMetrics.map((c) => {
        return (
          <div className="pipe-status__item" key={c.key}>
            <div className="pipe-status__key" style={{ color: theme.palette.text.primary }}>
              {c.key}
            </div>
            <div className="pipe-status__value">{c.render(c.val)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default withTheme(PipeStatus);
