import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import Button from 'Common/Button/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { Form, FormActions } from 'Common/forms';
import ExternalLink from 'Common/Links/ExternalLink';
import MonitoringActions from 'Redux/thunks/monitoring';

import { isProvisionerVersion2 } from 'Redux/selectors';
import Actions from 'Redux/thunks/subscriptions';
import PaymentMethodActions from 'Redux/thunks/payment-methods';
import { Box, Typography } from '@material-ui/core';
import PriceCalculator from '../../../components/price-calculator';
import ProductsEditor from '../../../components/products-editor';
import SelectPayment from '../../../components/products-editor/select-payment';
import './style.css';

const getSubType = (subscriptions, id) => {
  const sub = subscriptions.find((s) => s.id === id);
  if (sub) {
    return sub.type;
  }
  return '';
};

// TODO show which user is currently paying if not current user
class SettingsSubscriptionProducts extends React.Component {
  constructor(props) {
    super(props);

    this.isPaymentMethodOwner = () => {
      const paymentMethodInfo = this.props.subData.paymentmethod_info;
      const paymentOwnerUserId = get(paymentMethodInfo, 'owner.user_id');
      const userId = get(this.props.user, 'user_id');
      if (paymentOwnerUserId) {
        if (paymentOwnerUserId === userId) return true;
      }
      return false;
    };

    if (!this.isPaymentMethodOwner() && !props.isPortalAdmin) {
      this.props.router.push(`/subscription/${this.props.subId}/settings-subscription/basics`);
    }

    this.onSave = (ev) => {
      ev.preventDefault();
      const notValid = !this.state.paymentMethodId || this.state.productsAreInvalid;
      this.setState({ notValid });

      if (!notValid) {
        this.setState({ pleaseWait: true });
        this.props
          .updateProducts(this.props.subId, this.state.newProducts, this.state.paymentMethodId)
          .then(() => this.setState({ pleaseWait: false }))
          .catch(() => this.setState({ pleaseWait: false }));
      }
    };

    this.calculatePrice = () => {
      const subType = get(this.state.newProducts, 'datahub.size');
      const dataSize =
        subType !== 'developer' && subType !== 'developer-pro' ? this.state.expectedDataSize : 0;
      this.props
        .calcPrice(this.props.subId, this.state.newProducts, dataSize)
        .then((price) => this.setState({ price }));
    };

    this.debouncedCalculatePrice = debounce(this.calculatePrice, 400);

    this.productsChanged = (newProducts) => {
      this.setState(
        {
          newProducts: newProducts,
        },
        () => this.calculatePrice()
      );
    };

    this.paymentMethodChanged = (paymentMethodId) => {
      this.setState({
        paymentMethodId,
      });
    };

    this.hasChanges = () => {
      return this.state.price && !isEqual(this.state.products, this.state.newProducts);
    };

    this.handleExpectedDataSizeChanged = (ev) =>
      this.setState({ expectedDataSize: Number(ev.target.value) }, () =>
        this.debouncedCalculatePrice()
      );

    this.isDisabled = () => {
      let disabled = true;

      if (
        this.state.pleaseWait ||
        this.state.productsAreInvalid ||
        this.state.productsValidationAreInProgress ||
        !this.state.paymentMethodId ||
        !this.isPaymentMethodOwner()
      ) {
        disabled = true;
      } else if (
        this.props.subData.paymentmethod_id !== this.state.paymentMethodId ||
        !isEqual(this.state.products, this.state.newProducts)
      ) {
        disabled = false;
      }

      return disabled;
    };

    const products = cloneDeep(this.props.subData.products);
    // default setup for old subs
    if (!products.datahub) {
      products.datahub = { size: 'small' };
    }
    if (!products.datahub.backup) {
      products.datahub.backup = 'local-replicated';
    }
    this.state = {
      pleaseWait: false,
      products,
      newProducts: cloneDeep(products),
      paymentMethodId: this.props.subData.paymentmethod_id,
      paymentMethodInfo: this.props.subData.paymentmethod_info,
      productsAreInvalid: false,
      productsValidationAreInProgress: false,
      expectedDataSize: 0,
      currentDataSize: 0,
    };
  }

  componentDidMount() {
    this.props
      .calcPrice(this.props.subId, this.state.newProducts)
      .then((price) => this.setState({ price, currentPrice: price }));
    this.props.loadPaymentMethods();

    this.props
      .getData(this.props.subId)
      .then((data) => {
        let currentDataSize = 0;
        const results = get(data, 'disk.columns', []);
        for (let col of results) {
          if (col.includes('store-disk-usage')) currentDataSize = col[1];
        }
        currentDataSize = Math.ceil(currentDataSize / 1000 / 1000 / 1000);
        this.setState({ ...this.state, currentDataSize, expectedDataSize: currentDataSize });
      })
      .catch(() => this.setState({ ...this.state, currentDataSize: 0 }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.currentDataSize, this.state.currentDataSize)) {
      const subType = get(this.state.newProducts, 'datahub.size');
      const dataSize =
        subType !== 'developer' && subType !== 'developer-pro' ? this.state.expectedDataSize : 0;
      this.props
        .calcPrice(this.props.subId, this.state.newProducts, dataSize)
        .then((price) => this.setState({ price, currentPrice: price }));
    }

    if (!isEqual(this.props.subData.products, prevProps.subData.products)) {
      this.props.calcPrice(this.props.subId, this.state.newProducts).then((price) =>
        this.setState({
          price,
          currentPrice: price,
          products: cloneDeep(this.props.subData.products),
        })
      );
    }
  }

  render() {
    return (
      <main className="scrollArea">
        <Form onSubmit={this.onSave}>
          <div className="row">
            <div className="col gr-primary">
              <h2 className="heading-section">Change subscription capabilities</h2>
              <ProductsEditor
                products={this.state.newProducts}
                onChange={this.productsChanged}
                serviceType={this.props.subData.service}
              />
            </div>
            <div className="col gr-secondary pricing-area">
              <h2 className="heading-section">Current monthly price</h2>
              <p style={{ fontSize: '0.8rem' }}>
                Please notice that prices are estimates only and are not intended as actual price
                quotes. For more detailed information please visit{' '}
                <ExternalLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.sesam.io/pricing.html"
                >
                  Subscription Fee and payment terms
                </ExternalLink>
                {'.'}
              </p>
              {this.props.subData.type === 'trial' && <p>Free private trial</p>}
              {this.props.subData.type !== 'trial' && this.state.currentPrice && (
                <PriceCalculator
                  value={this.state.currentPrice}
                  shouldFetchNotificationRules={true}
                  disableExpectedDataSize
                  expectedDataSize={this.state.currentDataSize}
                  currentDataSize={this.state.currentDataSize}
                  onExpectedDataSizeChange={this.handleExpectedDataSizeChanged}
                  products={this.state.products}
                  isProvisionerVersion2={this.props.isProvisionerVersion2}
                />
              )}
              <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography variant={'subtitle2'}>All prices are exclusive of VAT.</Typography>
              </Box>
              {this.hasChanges() && (
                <div>
                  <h2 className="heading-section pricing-area__new">New monthly estimate</h2>
                  {this.state.price && (
                    <PriceCalculator
                      value={this.state.price}
                      shouldFetchNotificationRules={true}
                      expectedDataSize={this.state.expectedDataSize}
                      onExpectedDataSizeChange={this.handleExpectedDataSizeChanged}
                      products={this.state.newProducts}
                      isProvisionerVersion2={this.props.isProvisionerVersion2}
                    />
                  )}
                </div>
              )}
              <div>
                <SelectPayment
                  onChange={this.paymentMethodChanged}
                  paymentMethods={this.props.paymentMethods}
                  value={this.state.paymentMethodId}
                  selectedPaymentMethodInfo={this.state.paymentMethodInfo}
                />
                <FormActions>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      this.isPaymentMethodOwner()
                        ? ''
                        : 'Only payment method owners can change the products in this subscription'
                    }
                  >
                    <span>
                      <Button type="submit" disabled={this.isDisabled()}>
                        {this.state.pleaseWait ? 'Saving...' : 'Save'}
                      </Button>
                    </span>
                  </Tooltip>
                </FormActions>
                {this.state.notValid && !this.state.paymentMethodId && (
                  <div className="error-panel">Payment method is required.</div>
                )}
                {this.state.productsAreInvalid && (
                  <div className="error-panel">
                    One or more of the product settings are invalid.
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      </main>
    );
  }
}

SettingsSubscriptionProducts.propTypes = {
  type: PropTypes.string.isRequired,
  subData: PropTypes.object.isRequired,
  subId: PropTypes.string.isRequired,
  updateProducts: PropTypes.func.isRequired,
  calcPrice: PropTypes.func.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  loadPaymentMethods: PropTypes.func.isRequired,
  getData: PropTypes.func,
  user: PropTypes.object,
  isProvisionerVersion2: PropTypes.bool.isRquired,
  isPortalAdmin: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    subId: state.subscription.id,
    type: getSubType(state.subscriptions, state.subscription.id),
    subData: state.subscriptions.find((s) => s.id === state.subscription.id),
    user: state.user,
    paymentMethods: state.paymentMethods.filter((pm) => pm.is_verified),
    isProvisionerVersion2: isProvisionerVersion2(state),
    isPortalAdmin: state.user.isPortalAdmin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProducts: (subId, products, paymentMethodId) =>
      dispatch(Actions.updateProducts(subId, products, paymentMethodId)),
    calcPrice: (subId, products, expectedDataSize) =>
      dispatch(Actions.calcPrice(subId, products, expectedDataSize)),
    loadPaymentMethods: () => dispatch(PaymentMethodActions.loadAll()),
    getData: (subId) => dispatch(MonitoringActions.getSubscriptionStats(subId, false)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(SettingsSubscriptionProducts);
