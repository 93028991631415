import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import Actions, { findSub } from 'Redux/thunks/subscriptions';
import Button from 'Common/Button/Button';
import SesamToggleSwitch from 'Common/SesamToggleSwitch';
import ExternalLink from 'Common/Links/ExternalLink';

import AclLine from './acl-line';

import './style.css';
import { TestID } from '../../testID';

const firewallEnabled =
  'The firewall is deactivated and will allow access to the service API from all IP addresses.';
const firewallDisabled =
  'The firewall is activated and only the IP addresses listed below are allowed access to the service API.';

const ipv4RegEx =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/[0-9]{1,2})*$/;
const ipv6RegEx =
  /^(((([0-9a-fA-F]){1,4}):){7}((([0-9a-fA-F]){1,4})){1}|((([0-9a-fA-F]){1,4}):){3}((([0-9a-fA-F]){1,4})){1}(\/[0-9]{1,2}))$/;

function isValidIP(ip) {
  return ipv4RegEx.test(ip) || ipv6RegEx.test(ip);
}

const NetworkAcl = (props) => {
  const [error, setError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [aclList, setAclList] = useState(props.acl);
  const [publicAccess, setPublicAccess] = useState(props.publicAccess);

  useEffect(() => {
    let err = false;
    if (aclList.some((acl) => !isValidIP(acl.address))) err = true;
    setError(err);
  }, [aclList]);

  const handleToggle = () => {
    setPublicAccess((value) => !value);
  };
  const handleAdd = () => {
    const aclObject = { address: '', description: '', access: 'Allow' };
    setAclList([...aclList, { ...aclObject }]);
  };
  const handleDelete = (index) => {
    const newAclList = aclList.filter((_acl, i) => i !== index);
    setAclList(newAclList);
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;

    switch (name) {
      case 'address':
        setAclList(
          aclList.map((acl, i) => {
            if (i === index)
              return { address: value, description: acl.description, access: 'Allow' };
            return acl;
          })
        );
        break;
      case 'description':
        setAclList(
          aclList.map((acl, i) => {
            if (i === index) return { address: acl.address, description: value, access: 'Allow' };
            return acl;
          })
        );
        break;
    }
  };
  const handleSave = () => {
    setSaving(true);
    props
      .onSave(props.subId, aclList, publicAccess)
      .then(() => {
        setSaving(false);
      })
      .catch(() => console.log('An error occurred while saving acl!'));
  };

  return (
    <div>
      <div>
        <h2 className="heading-section" style={{ display: 'inline' }}>
          Firewall
        </h2>
        <span style={{ float: 'right', marginTop: '5px' }}>
          <SesamToggleSwitch
            leftLabel=""
            rightLabel=""
            isOn={!publicAccess}
            onToggle={() => handleToggle()}
            style={{ float: 'right' }}
          />
        </span>
      </div>

      {props.publicIPOut && (
        <div>
          <p>
            Sesam outbound IP:{' '}
            <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.sesam.io/hub/features/network-policy.html"
              data-testid={TestID.NetworkPolicyLink}
            >
              {props.publicIPOut}
            </ExternalLink>
          </p>
        </div>
      )}

      <div className="acl-description">
        <p>{!publicAccess ? firewallDisabled : firewallEnabled}</p>
      </div>

      {!publicAccess && (
        <React.Fragment>
          {aclList.length !== 0 && (
            <div className="acl-list">
              <p className="acl-list-header">Allowed IP addresses</p>
              {aclList.map((item, index) => (
                <AclLine
                  {...item}
                  key={index}
                  isValidIP={isValidIP}
                  onDelete={() => handleDelete(index)}
                  onChange={(e) => handleChange(e, index)}
                />
              ))}
            </div>
          )}

          <div className="acl-add">
            <Button onClick={handleAdd}>
              {aclList.length ? '+ Add another IP address' : '+ Add allowed IP addresses'}
            </Button>
          </div>
        </React.Fragment>
      )}

      {error && <p className="acl-error">*Field must be a valid IP or CIDR range</p>}
      <div className="toolbar toolbar--right">
        <Button
          disabled={
            error || (isEqual(props.acl, aclList) && props.publicAccess === publicAccess) || saving
          }
          onClick={handleSave}
        >
          {saving ? 'Saving…' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

NetworkAcl.propTypes = {
  subId: PropTypes.string,
  publicAccess: PropTypes.bool,
  acl: PropTypes.array,
  onSave: PropTypes.func,
  publicIPOut: PropTypes.string,
};

const mapStateToProps = (state) => {
  const sub = findSub(state.subscriptions)(state.subscription.id);
  return {
    subId: state.subscription.id,
    publicAccess: get(sub, 'network["public-access"]', false),
    acl: get(sub, 'network["network-acl"]', []),
    publicIPOut: get(sub, 'provisioning_result.public_ip_out'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: (subId, acl, publicAccess) =>
      dispatch(Actions.updateNetworkAcl(subId, cloneDeep(acl), publicAccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkAcl);
