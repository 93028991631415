import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import JsonEditor from 'Common/JsonEditor/JsonEditor';

import Paper from '@material-ui/core/Paper';
import ExternalLink from 'Common/Links/ExternalLink';

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  paper: {
    display: 'flex',
    flex: 1,
  },
  blueText: {
    color: theme.palette.primary.main,
  },
}));

const HopsWizardSummary = (props) => {
  const classes = useStyle();

  const { setValue, state } = props;
  const { dataset, sourceProp, targetProp, hop } = state;

  useEffect(() => {
    let sp;
    let tp;

    try {
      const jsp = JSON.parse(sourceProp);
      sp = jsp;
    } catch (e) {
      sp = sourceProp;
    }

    try {
      const jtp = JSON.parse(targetProp);
      tp = jtp;
    } catch (e) {
      tp = targetProp;
    }

    const initialHop = [
      'merge-union',
      [
        'hops',
        {
          datasets: [`${dataset} t`],
          where: ['eq', sp, tp],
        },
      ],
    ];

    setValue('hop', initialHop);
  }, [dataset, setValue, sourceProp, targetProp]);

  return (
    <div className={classes.container}>
      <p>
        You are about to add data from{' '}
        <span className={classes.blueText}>{props.state.dataset}</span> to{' '}
        <span className={classes.blueText}>{props.bag.sourceId}</span>
      </p>
      <p>Below you can see the DTL that has been generated. Feel free to edit it.</p>
      <p>
        To read more about hops,{' '}
        <ExternalLink href="https://docs.sesam.io/DTLReferenceGuide.html#hops">
          click here!
        </ExternalLink>
      </p>
      <Paper className={classes.paper} variant="outlined" square>
        <JsonEditor rawJson={hop} onChange={(val) => props.setValue('hop', val)} />
      </Paper>
    </div>
  );
};

HopsWizardSummary.propTypes = {
  bag: PropTypes.shape({
    sourceId: PropTypes.string,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  state: PropTypes.shape({
    dataset: PropTypes.string,
    hop: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])),
    sourceProp: PropTypes.string,
    targetProp: PropTypes.string,
  }),
};

export default HopsWizardSummary;
