import React from 'react';
import { connect } from 'react-redux';

import HeaderButton from '../header-button';
import AuthActions from 'Redux/thunks/auth';
import { darkModeActivated, darkModeDeactivated } from 'Redux/thunks/global';
import Actions from 'Redux/thunks/user-profile';
import { CurrentUserBanner } from '../user-banner';
import UserAvatar from '../user-avatar/UserAvatarContainer';
import MenuToggler from '../menu-toggler';
import ToggleSwitch from 'Common/ToggleSwitch';
import ExternalLink from 'Common/Links/ExternalLink';
import DangerText from 'Common/Text/DangerText';
import { MenuAction, MenuActionLink, MenuItem, MenuSeparator } from '../menu';
import SesamToggleSwitch from 'Common/SesamToggleSwitch';

import './style.css';
import { AppDispatch, RootState } from '../..';
import { Typography } from '@material-ui/core';

const anchorPoint = {
  anchor: { hPos: 'right', vPos: 'bottom' },
  popover: { hPos: 'right', vPos: 'top' },
};

type UserMenuProps = {
  email: string;
  logout: () => {};
  changePortalAdminPowerActivationState: () => {};
  isPortalAdmin: boolean;
  canTogglePortalAdminPowers: boolean;
  activateDarkMode: () => {};
  deactivateDarkMode: () => {};
  darkModeActive: boolean;
  userName: string;
};

function UserMenu(props: UserMenuProps) {
  function setPortalAdminPowerActivationState(activate) {
    props.changePortalAdminPowerActivationState(activate).then(() => {
      // We reload the page from scratch, to make sure that the gui reflects the new setting.
      location.reload();
    });
  }

  const label = (
    <UserAvatar className="user-menu__toggler" email={props.email} title={props.userName} />
  );

  let portalAdminToggle = null;
  let adminGUILink = null;
  if (props.canTogglePortalAdminPowers) {
    if (props.isPortalAdmin) {
      portalAdminToggle = (
        <MenuAction
          data-selenium="activate-portal-admin-powers"
          label="Deactivate portal-admin powers"
          onClick={(event) => {
            event.preventDefault();
            setPortalAdminPowerActivationState(false);
          }}
        />
      );
      adminGUILink = (
        <MenuAction
          onClick={(event) => {
            event.preventDefault();
            location.href = '/api/admin';
          }}
          label="Admin GUI"
        />
      );
    } else {
      portalAdminToggle = (
        <MenuAction
          data-selenium="activate-portal-admin-powers"
          label={<DangerText>Activate portal-admin powers</DangerText>}
          onClick={(event) => {
            event.preventDefault();
            setPortalAdminPowerActivationState(true);
          }}
        />
      );
    }
  }

  return (
    <HeaderButton className="user-menu" darkModeActive={props.darkModeActive}>
      <MenuToggler
        anchorPoint={anchorPoint}
        data-selenium-user-email={props.email}
        data-selenium="user-menu"
        label={label}
      >
        <MenuItem>
          <CurrentUserBanner className="user-menu__user-banner" />
        </MenuItem>
        <MenuSeparator />
        <MenuActionLink to="/user/profile" label="Profile" />
        <MenuActionLink to="/user/payment-methods" label="Payment methods" />
        {portalAdminToggle}
        {adminGUILink}
        <ExternalLink href="https://support.sesam.io/hc" className="menu__action">
          Help Center
        </ExternalLink>
        <MenuActionLink to="/prices" label="Prices" />
        <MenuSeparator />
        <div style={{ marginLeft: '10px' }}>
          Dark mode
          <SesamToggleSwitch
            isOn={props.darkModeActive}
            leftLabel=""
            rightLabel=""
            onToggle={(e) => {
              const checked = e.target.checked;
              checked ? props.activateDarkMode() : props.deactivateDarkMode();
            }}
            style={{ right: '5px', float: 'right' }}
          />
        </div>
        <MenuSeparator />
        <MenuAction data-selenium="logout" label="Logout" onClick={props.logout} />
      </MenuToggler>
    </HeaderButton>
  );
}

function mapStateToProps(state: RootState) {
  let canTogglePortalAdminPowers = false;
  if (
    state.user.supportedOperations &&
    state.user.supportedOperations.activate_portal_admin_powers &&
    state.user.supportedOperations.activate_portal_admin_powers.enabled
  ) {
    canTogglePortalAdminPowers = true;
  }
  let isPortalAdmin = false;
  if (state.user.isPortalAdmin) {
    isPortalAdmin = true;
  }
  return {
    email: state.user.email,
    subscriptions: state.subscriptions,
    userName: state.user.name,
    isPortalAdmin,
    canTogglePortalAdminPowers,
    darkModeActive: state.theme.dark,
    connectedTo: state.subscription && state.subscription.connected ? state.subscription.id : '',
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    logout: () => dispatch(AuthActions.logout()),
    changePortalAdminPowerActivationState: (activate: boolean) =>
      dispatch(Actions.changeProfile(null, { isPortalAdmin: activate })),
    activateDarkMode: () => dispatch(darkModeActivated()),
    deactivateDarkMode: () => dispatch(darkModeDeactivated()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
