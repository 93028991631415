import React, { ComponentType } from 'react';
import Button from 'Common/Button/Button';
import { Theme } from '@material-ui/core';

export const EntityBrowserListActionsFooter: React.FC<
  React.ComponentProps<typeof Button> & {
    theme: Theme;
    repostDeadEntities: () => void;
    disabled?: boolean;
  }
> = ({ theme, repostDeadEntities, disabled }, props) => (
  <div
    style={{
      position: 'absolute',
      bottom: 0,
      padding: 10,
      width: 'calc(100% - 2px)',
      display: 'flex',
      justifyContent: 'flex-end',
      border: `1px solid ${theme.palette.divider}`,
      borderLeft: 0,
      borderRight: 0,
    }}
  >
    <Button
      onClick={repostDeadEntities}
      className="dataset-inspector-filter__action"
      disabled={disabled}
      {...props}
    >
      Repost all...
    </Button>
  </div>
);
