import { connect } from 'react-redux';
import React, { useRef, useCallback, useState } from 'react';
import get from 'lodash/get';

import { getEnvironment } from 'Internals/utils';
import Button from 'Common/Button/Button';
import ExternalLink from 'Common/Links/ExternalLink';
import SesamToggleSwitch from 'Common/SesamToggleSwitch';
import { isProvisionerVersion2 } from 'Redux/selectors';
import Actions, { findSub } from 'Redux/thunks/subscriptions';
import useAsync from 'Hooks/useAsync';
import Page from '../page';
import PageHeader, { PageHeaderGroup, PageHeaderTitle } from '../page-header';
import TabbedNav from '../tabbed-nav';
import { AppDispatch, RootState } from 'src';
import { SomeObject } from '../../types/types';

interface BrowsePageProps {
  children: React.ReactElement;
  sub: SomeObject;
  subId: string;
  products: SomeObject;
  environment: string;
  serviceType: string;
  paymentMethodId: string;
  isProvisionerVersion2: boolean;
  updateProducts: Function;
  hasSearch: boolean;
  loadAll: Function;
}

function BrowsePage(props: BrowsePageProps) {
  let registeredRefreshes = useRef([]);

  const [showSearchToggleSwitch, setShowSearchToggleSwitch] = useState(
    props.environment === 'developer-pro' &&
      props.serviceType === 'in-cloud' &&
      props.isProvisionerVersion2 &&
      !props.products.search
      ? true
      : false
  );

  const _refresh = useCallback(() => {
    registeredRefreshes.current.forEach((cb) => cb());
  }, []);

  const [refresh] = useAsync(_refresh);

  const registerRefresh = useCallback((func) => {
    registeredRefreshes.current.push(func);
  }, []);

  const unregisterRefresh = useCallback((func) => {
    registeredRefreshes.current = registeredRefreshes.current.filter((c) => c !== func);
  }, []);

  const navTabs = [
    {
      label: 'Entity types',
      to: `/subscription/${props.subId}/browse/entity-types`,
    },
    {
      label: 'Models',
      to: `/subscription/${props.subId}/browse/models`,
    },
  ];

  if (props.hasSearch) {
    navTabs.unshift({
      label: 'Search',
      to: `/subscription/${props.subId}/browse/search`,
    });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    let updatedProducts = JSON.parse(JSON.stringify(props.products));
    if (name === 'search') {
      if ('search' in updatedProducts) {
        delete updatedProducts.search;
      } else {
        updatedProducts.search = true;
      }
    }
    // to remove legacy sla properties when the subscription has been downgraded manually by support
    if (
      updatedProducts.datahub.size === 'developer' ||
      updatedProducts.datahub.size === 'developer-pro'
    )
      delete updatedProducts.sla;

    if (showSearchToggleSwitch && updatedProducts.search) {
      const searchToggle = document.querySelector('#showSearchToggleSwitch');
      searchToggle.className = 'fadeOut';
      setTimeout(() => {
        setShowSearchToggleSwitch(false);
      }, 650);
    }

    props.updateProducts(props.subId, updatedProducts, props.paymentMethodId);
  }

  return (
    <Page dataSelenium="subscription-browse-pageview">
      <PageHeader>
        <PageHeaderTitle>Browse data</PageHeaderTitle>
        <PageHeaderGroup>
          <Button theme="primary" onClick={() => refresh()}>
            Refresh
          </Button>
        </PageHeaderGroup>
      </PageHeader>
      {showSearchToggleSwitch && (
        <div style={{ margin: '10px 0 10px 1px', width: '480px' }} id="showSearchToggleSwitch">
          Enable{' '}
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.sesam.io/features/integrated-search.html"
          >
            integrated search
          </ExternalLink>{' '}
          and property lineage (free){' '}
          <SesamToggleSwitch
            leftLabel="Off"
            rightLabel="On"
            isOn={props.products.search}
            onToggle={(e) => {
              if (!props.products.search) {
                const checked = e.target.checked;
                handleChange({ target: { name: 'search', value: checked } });
              }
            }}
            style={{ float: 'right' }}
          />
        </div>
      )}
      <TabbedNav nav={navTabs}>
        {React.cloneElement(props.children, {
          registerRefresh: registerRefresh,
          unregisterRefresh: unregisterRefresh,
        })}
      </TabbedNav>
    </Page>
  );
}

const mapStateToProps = (state: RootState) => {
  const sub = findSub(state.subscriptions)(state.subscription.id);

  return {
    sub,
    subId: get(sub, 'id', ''),
    products: get(sub, 'products', {}),
    environment: sub ? getEnvironment(sub.products) : '',
    serviceType: get(sub, 'service', ''),
    paymentMethodId: get(sub, 'paymentmethod_id', ''),
    isProvisionerVersion2: isProvisionerVersion2(state),
    hasSearch: get(sub, 'products.search'),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateProducts: (subId, products, paymentMethodId) =>
    dispatch(Actions.updateProducts(subId, products, paymentMethodId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrowsePage);
