import React from 'react';
import PropTypes from 'prop-types';
import CenteredLayout from '../../layout/centered';
import FocusPanel from '../focus-panel';

import Button from 'Common/Button/Button';
import ExternalLink from 'Common/Links/ExternalLink';

import './style.css';
import KeyValue from '../key-value';

function TermsOfService(props) {
  if (!props.user) return null;
  if (props.isNew3rdPartyAuthUser) {
    const creds = {
      Name: props.user.name,
      Email: props.user.email,
    };
    return (
      <CenteredLayout renderHeader={false}>
        <FocusPanel>
          <p>Welcome to Sesam!</p>
          <p>You have created a new user with these credentials:</p>

          <KeyValue list={creds} />

          <p>
            To use Sesam, you have to agree to the{' '}
            <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://sesam.io/terms.html"
            >
              Terms of Service
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://sesam.io/privacy.html"
            >
              Privacy Policy
            </ExternalLink>
          </p>
          <div className="tos__buttons">
            <Button
              loading={props.loading.cancel}
              className="tos__button"
              theme="danger"
              text="Cancel"
              onClick={props.onCancel}
            />
            <Button
              className="tos__button"
              loading={props.loading.cancel}
              text="I agree"
              onClick={props.onApprove}
            />
          </div>
        </FocusPanel>
      </CenteredLayout>
    );
  } else {
    return (
      <CenteredLayout>
        <FocusPanel>
          <p>There was an update to Sesam's Terms of Service</p>
          <p>
            To continue, you have to agree to the updated{' '}
            <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://sesam.io/terms.html"
            >
              Terms of Service
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://sesam.io/privacy.html"
            >
              Privacy Policy
            </ExternalLink>
          </p>
          <div className="tos__buttons">
            <Button
              loading={props.loading.cancel}
              className="tos__button"
              theme="danger"
              text="Cancel"
              onClick={props.onCancel}
            />
            <Button
              loading={props.loading.approve}
              className="tos__button"
              text="Continue"
              onClick={props.onApprove}
            />
          </div>
        </FocusPanel>
      </CenteredLayout>
    );
  }
}

TermsOfService.propTypes = {
  loading: PropTypes.shape({
    cancel: PropTypes.func.isRequired,
    approve: PropTypes.func.isRequired,
  }).isRequired,
  isNew3rdPartyAuthUser: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  onApprove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TermsOfService;
